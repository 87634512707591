export default defineNuxtPlugin(() => {
  const instance = $fetch.create({
    headers: useRequestHeaders(["cookie", "x-forwarded-for"]),
    async onResponseError({ request, response }) {
      console.error("[http] response error", request, response);
    },
  });

  return {
    provide: {
      http: instance,
    },
  };
});
