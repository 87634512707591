import type { RouteLocationNormalizedGeneric } from "#vue-router";
import type { SupabaseTable } from "~/types/table.types";
import type { SSO_PROVIDER } from "~/utils/constants";

export function useAuth() {
  const { $http } = useNuxtApp();
  const toast = useToast();
  const supabase = useSupabaseClient();

  const isLoading = useState<boolean>(() => false);
  const email = useState<string>(() => "");
  const password = useState<string>(() => "");
  const passwordConfirm = useState<string>(() => "");
  const firstName = useState<string>(() => "");
  const lastName = useState<string>(() => "");
  const provider = useState<ValueOf<typeof SSO_PROVIDER> | null>();
  // TODO: use `datetime` to validate the availablilty by using the browser cookie.
  const canSendEmail = useState<boolean>(() => true);

  const step = useState<"login" | "signup" | "confirm">(
    "authStep",
    () => "login",
  );
  watch(step, () => {
    if (step.value === "signup" || step.value === "login") {
      password.value = "";
    }
  });

  const {
    user,
    stripeCustomer,
    credits,
    dayPlans,
    subscriptions,
    ipAddressWhitelist,
  } = useUser();
  const { organizations } = useOrganizaiton();
  const { trackEvent, TRACKING_EVENT } = useEventTracker();

  function openLoginModalIfNotLoggedIn() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      if (!user.value) {
        const AuthLoginModal = await defineAsyncComponent(
          () => import("~/components/Auth/LoginModal.vue"),
        );
        useModal().open(AuthLoginModal, {
          onSuccess: () => resolve(),
        });
      } else {
        resolve();
      }
    });
  }

  // BUG: Supbase JS Client doesn't auto-detect users tokens in browser cookie.
  // https://github.com/nuxt-modules/supabase/issues/288
  async function detectSessionInUrl(route: RouteLocationNormalizedGeneric) {
    const accessToken =
      route.hash?.split("&")?.[0]?.split("=")?.[1] ||
      (route.query.access_token as string);
    const refreshToken =
      route.hash?.split("&")?.[3]?.split("=")?.[1] ||
      (route.query.refresh_token as string);

    if (accessToken && refreshToken) {
      // When either access token or refresh token is invalid.
      if (accessToken === "unauthorized_client") {
        return navigateTo({ name: ROUTE_NAMES.FORGOT });
      }

      const { data, error } = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken,
      });

      // TODO: Handling error
      if (error) {
        throw error;
      }

      if (data.user) {
        await fetchMe();
      }
    }
  }

  async function login(params?: {
    sso?: {
      provider: string;
      accessToken: string;
    };
  }) {
    try {
      isLoading.value = true;

      const resp = await $fetch(`/api/v1/auth/login`, {
        method: "POST",
        body: {
          email: email.value,
          password: password.value,
          ...params,
        },
      });

      if (resp.user.user_metadata.enabled_2fa) {
        step.value = "confirm";
        toast.add({
          icon: "i-lucide-inbox",
          title: "Please confirm your email.",
          description: `If you didn't get the email, please follow "Resend Email" button.`,
          timeout: 0,
        });
      } else {
        await supabase.auth.setSession(resp.session);

        // Todo : Fixing a login bug in the Windows Zoom app
        user.value = resp.user;
        password.value = "";
        passwordConfirm.value = "";

        await fetchMe();

        const firstName = user.value.user_metadata.first_name;
        const lastName = user.value.user_metadata.last_name;
        useToast().add({
          icon: "i-lucide-party-popper",
          color: "green",
          title: `Welcome, ${firstName} ${lastName}!`,
        });

        localStorage.setItem(
          Constants.LOCAL_STORAGE_KEYS.USER_EMAIL,
          email.value,
        );

        trackEvent(TRACKING_EVENT.AUTH.SIGNED_IN, {
          provider: params?.sso?.provider || "email",
        });

        for (const i of organizations.value) {
          switch (i.slug) {
            case "nexon-japan":
              navigateTo({
                name: ROUTE_NAMES.SLUG_MEETINGS,
                params: { slug: i.slug },
              });
              break;
            default:
              break;
          }
        }

        goToRedirectIfPossible();

        return resp;
      }
    } catch (err) {
      const { data } = useResponseError<{ message: string }>(err);

      switch (data.message) {
        case "Please confirm your email.":
          step.value = "confirm";
          break;
        default:
          toast.add({
            icon: "i-lucide-shield-alert",
            color: "red",
            title: "Request Failed",
            description: data.message || "Please check your input.",
            timeout: 0,
          });
          trackEvent(TRACKING_EVENT.AUTH.SIGNING_IN_FAILED, {
            provider: params?.sso?.provider || "email",
            ...data,
          });
          console.error("failed_to_signIn", err);
          break;
      }

      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  async function verifyToken({
    email,
    token,
    sso,
  }: {
    email: string;
    token: string;
    sso?: {
      provider?: string;
    };
  }) {
    isLoading.value = true;

    try {
      const resp = await $fetch(`/api/v1/auth/confirm`, {
        method: "POST",
        body: { email, token },
      });

      await supabase.auth.setSession(resp.session);
      await fetchMe();

      password.value = "";
      passwordConfirm.value = "";
      provider.value = null;

      if (user.value) {
        const firstName = user.value.user_metadata.first_name;
        const lastName = user.value.user_metadata.last_name;
        useToast().add({
          icon: "i-lucide-party-popper",
          color: "green",
          title: `Welcome, ${firstName} ${lastName}!`,
        });
      }

      trackEvent(TRACKING_EVENT.AUTH.CONFIRMED_SIGN_UP, {
        provider: sso?.provider || "email",
      });

      localStorage.setItem(Constants.LOCAL_STORAGE_KEYS.USER_EMAIL, email);

      goToRedirectIfPossible();
    } catch (err) {
      const { data } = useResponseError<{ message: string }>(err);

      toast.add({
        icon: "i-lucide-shield-alert",
        color: "red",
        title: "Request Failed",
        description: data.message,
        timeout: 0,
      });

      trackEvent(TRACKING_EVENT.AUTH.CONFIRM_SIGN_UP_FAILED);

      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  function signUp() {
    isLoading.value = true;

    return $fetch(`/api/v1/auth/new`, {
      method: "POST",
      body: {
        email: email.value,
        password: password.value,
        passwordConfirm: passwordConfirm.value,
        firstName: firstName.value,
        lastName: lastName.value,
        provider: provider.value,
      },
    })
      .then(() => {
        // This is for reset previous logged in user.
        user.value = null;
        password.value = "";
        passwordConfirm.value = "";
        step.value = "confirm";

        toast.add({
          icon: "i-lucide-inbox",
          title: `Please Confirm Email`,
          description: `We sent a confirmation code to your email. Please check your inbox.`,
          timeout: 0,
        });

        trackEvent(TRACKING_EVENT.AUTH.SIGNED_UP);

        localStorage.setItem(
          Constants.LOCAL_STORAGE_KEYS.USER_EMAIL,
          email.value,
        );
      })
      .catch((err) => {
        const { data } = useResponseError<{ message: string }>(err);

        toast.add({
          icon: "i-lucide-shield-alert",
          color: "red",
          title: "Sign Up Failed",
          description: data.message || "Please check your input.",
          timeout: 0,
        });

        trackEvent(TRACKING_EVENT.AUTH.SIGNED_UP_FAILED);
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  function sendPasswordRecoveryEmail(email: string) {
    isLoading.value = true;

    return $fetch(`/api/v1/auth/reset`, {
      method: "POST",
      body: { email },
    })
      .then((resp) => {
        toast.add({
          icon: "i-lucide-check",
          color: "emerald",
          title: "Please check your inbox.",
          description: "Follow next step to reset your password.",
        });

        canSendEmail.value = false;
        const OneMinute = 1000 * 60;
        setTimeout(() => (canSendEmail.value = true), OneMinute);

        trackEvent(TRACKING_EVENT.AUTH.REQUESTED_PASSWORD_RESET);

        return resp;
      })
      .catch((error) => {
        trackEvent(
          TRACKING_EVENT.AUTH.REQUEST_PASSWORD_RESET_FAILED,
          error.data.data,
        );
        throw error.data;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function resetPassword({
    password,
    passwordConfirm,
  }: {
    password: string;
    passwordConfirm: string;
  }) {
    try {
      isLoading.value = true;

      await $fetch("/api/v1/users/password", {
        method: "PUT",
        body: {
          password,
          passwordConfirm,
        },
      });
      toast.add({
        color: "green",
        title: "Your password has been changed.",
      });

      trackEvent(TRACKING_EVENT.AUTH.RESET_PASSWORD);

      navigateTo({ name: ROUTE_NAMES.MEETING_OPEN });
    } catch (error) {
      const { data } = useResponseError<{ message: string }>(error);

      toast.add({
        color: "red",
        title: data.message,
      });
    } finally {
      isLoading.value = false;
    }
  }

  async function resendOtp(email: string) {
    try {
      await $fetch("/api/v1/auth/send_otp", {
        method: "POST",
        body: { email },
      });

      toast.add({
        title: "Please confirm your email.",
        color: "green",
      });
      trackEvent(TRACKING_EVENT.AUTH.RESENT_OTP);
    } catch (err) {
      const { data } = useResponseError<{ message: string }>(err);
      toast.add({
        icon: "i-lucide-shield-alert",
        color: "red",
        title: "Request Failed",
        description: data.message || "Please check your input.",
        timeout: 0,
      });
    }
  }

  async function signOut(options?: { showAlert: boolean }) {
    try {
      const showAlert = options?.showAlert ?? true;

      await supabase.auth.signOut();

      if (showAlert === true) {
        toast.add({
          icon: "i-lucide-log-out",
          color: "emerald",
          title: "You're signed out.",
        });
      }

      trackEvent(TRACKING_EVENT.AUTH.SIGNED_OUT);

      user.value = null;
      credits.value = {};
      subscriptions.value = {};

      location.reload();
    } catch (err) {
      console.error("signOut Failed", err);
    }
  }

  function fetchMe() {
    const { organizationId } = useMeeting();
    isLoading.value = true;

    return $http(`/api/v1/auth/me`)
      .then((resp) => {
        user.value = resp.user;
        credits.value = resp.credits;
        dayPlans.value = resp.dayPlans;
        subscriptions.value = resp.subscriptions as {
          [tier in TierValue]?: SupabaseTable.Subscription;
        };
        organizations.value =
          resp.organizations as SupabaseTable.Organization[];
        stripeCustomer.value = resp.stripeCustomer;

        const isOrganizationExistsButNoCookie =
          organizations.value.length && !organizationId.value;
        if (isOrganizationExistsButNoCookie) {
          organizationId.value = organizations.value[0].id;
        }

        if (resp.user?.user_metadata) {
          ipAddressWhitelist.value =
            resp.user.user_metadata.ip_address_whitelist || [];
        }

        return resp;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function goToRedirectIfPossible() {
    const route = useRoute();
    if (route.query.redirect) {
      await navigateTo(route.query.redirect as string, { replace: true });
    } else {
      switch (route.name) {
        case ROUTE_NAMES.FORGOT:
        case ROUTE_NAMES.LOGIN:
        case ROUTE_NAMES.NEW:
          await navigateTo({ name: ROUTE_NAMES.HOME });
          break;
      }
    }
  }

  return {
    isLoading,
    email,
    password,
    passwordConfirm,
    firstName,
    lastName,
    step,
    provider,
    canSendEmail,
    openLoginModalIfNotLoggedIn,
    fetchMe,
    login,
    verifyToken,
    detectSessionInUrl,
    signUp,
    sendPasswordRecoveryEmail,
    resetPassword,
    resendOtp,
    signOut,
    goToRedirectIfPossible,
  };
}
