import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime.js";
import localizedFormat from "dayjs/plugin/localizedFormat";

export default defineNuxtPlugin((nuxtApp) => {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(localizedFormat);

  nuxtApp.provide("dayjs", dayjs);
});

declare module "#app" {
  interface NuxtApp {
    $dayjs: typeof dayjs;
  }
}
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $dayjs(date?: dayjs.ConfigType): typeof dayjs;
  }
}
