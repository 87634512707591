import type { RealtimeChannel } from "@supabase/supabase-js";
import type { Database } from "~/types/database.types";
import { BOT_STATUS_EXTENDED, MEETING_PLATFORM } from "~/types/recallBot.types";
import type { SupabaseTable } from "~/types/table.types";

export function useMeeting() {
  const supabase = useSupabaseClient<Database>();

  const toast = useToast();
  const { user } = useUser();
  const { humanReadableLanguages } = useSupportedLanguages();
  const organizationId = useCookie<string | null>("organizationId", {
    default: () => null,
  });
  const categoryOptions = useState<{ id: string; label: string }[]>(() => []);

  const meeting = useState<SupabaseTable.Meeting | null>(() => null);
  const form = useState<{
    meetingUrl: string;
    categories: { id: string; label: string }[];
    mainLanguage: string;
  }>(() => ({
    meetingUrl: "",
    categories: [],
    mainLanguage: "",
  }));

  const guessedMeetingPlatform = computed(() => {
    if (form.value.meetingUrl.includes("zoom")) {
      return MEETING_PLATFORM.ZOOM;
    } else if (form.value.meetingUrl.includes("meet.google.com")) {
      return MEETING_PLATFORM.GOOGLE_MEET;
    } else if (
      form.value.meetingUrl.includes("teams.microsoft.com") ||
      form.value.meetingUrl.includes("teams.live.com")
    ) {
      return MEETING_PLATFORM.MS_TEAMS;
    } else {
      return null;
    }
  });

  const isMeetingUrlAllowed = computed<boolean>(() => {
    if (guessedMeetingPlatform.value) {
      return Meeting.AllowedMeetingUrlFormat[
        guessedMeetingPlatform.value
      ].patterns.some((pattern) => pattern.test(form.value.meetingUrl));
    } else {
      return false;
    }
  });

  const isLiveMeeting = computed(() => {
    const { lastBotStatusChange } = useMeetingBot();

    return (
      lastBotStatusChange.value?.code === BOT_STATUS_EXTENDED.IN_CALL_RECORDING
    );
  });

  const isFreeTierMeeting = computed(
    () => meeting.value?.tier === Tier.OnlineMeeting.Free,
  );

  const isMeetingHost = computed(() => {
    return user.value?.id === meeting.value?.user_id;
  });

  const botRecordStartedAt = computed(() => {
    const { bot } = useMeetingBot();

    return bot.value?.metadata.status_changes.find(
      (i) => i.code === BOT_STATUS_EXTENDED.IN_CALL_RECORDING,
    )?.created_at;
  });

  const elapsedDuration = computed(() => {
    const { now } = useTime();
    return dayjs.duration(now.value.diff(botRecordStartedAt.value));
  });
  const remainingDuration = computed(() => {
    const { now } = useTime();
    const endTime = dayjs(botRecordStartedAt.value).add(
      Meeting.MinutesOfFreeTier,
      "minutes",
    );

    return dayjs.duration(endTime.diff(now.value));
  });

  const isDownloadRestrictedMeeting = computed(() => {
    const userIdList: string[] = ["b403f8c6-16b2-4d45-8d43-bae9f5cabbbb"];
    if (meeting.value?.user_id) {
      return userIdList.includes(meeting.value.user_id);
    } else {
      return false;
    }
  });

  const isGussedMeetingPlatformSingleStream = computed(() => {
    switch (guessedMeetingPlatform.value) {
      case MEETING_PLATFORM.GOOGLE_MEET:
      case MEETING_PLATFORM.MS_TEAMS:
        return true;
      default:
        return false;
    }
  });

  const isMultiStreamMeeting = computed(() => {
    switch (meeting.value?.meeting_platform) {
      case MEETING_PLATFORM.ZOOM:
        return true;
      default:
        return false;
    }
  });

  const supportedLanguages = computed(() => {
    if (!meeting.value?.target_languages?.length) {
      return [];
    }

    return meeting.value.target_languages.map((i) => ({
      value: i,
      label: humanReadableLanguages.value[i],
    }));
  });

  let supabaseChannel: RealtimeChannel;
  function subscribeMeetingChanges({ meetingId }: { meetingId: string }) {
    supabaseChannel = supabase
      .channel("MeetingChannel")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "Meeting",
          filter: `id=eq.${meetingId}`,
        },
        (payload) => {
          const oldValue = payload.old as SupabaseTable.Meeting;
          const newValue = payload.new as SupabaseTable.Meeting;

          meeting.value = newValue;

          if (!oldValue.end_at && newValue.end_at) {
            showMeetingEndToast();
          }
        },
      )
      .subscribe();
  }

  function unsubscribeMeetingChanges() {
    supabaseChannel?.unsubscribe();
  }

  function showMeetingEndToast() {
    const FeedbackSurveyUrl =
      "https://docs.google.com/forms/d/1Ae2AOivpqh-3RcZO16_P-UFJzhVJccSX6l-gafaa6ck/viewform?edit_requested=true";

    toast.add({
      color: "primary",
      icon: "i-lucide-message-circle-heart",
      title: "This meeting has ended.",
      description:
        "Your feedback is valuable — share insights for improvement.",
      timeout: 0,
      actions: [
        {
          label: "Share Feedback",
          color: "black",
          click: () => navigateTo(FeedbackSurveyUrl, { external: true }),
        },
      ],
    });
  }

  async function fetchCategories() {
    try {
      const { data } = await $fetch("/api/v1/categories");
      categoryOptions.value = data as { id: string; label: string }[];
    } catch (err) {
      console.log(err);
    }
  }

  return {
    form,
    meeting,
    organizationId,
    botRecordStartedAt,
    elapsedDuration,
    remainingDuration,
    guessedMeetingPlatform,
    isMeetingUrlAllowed,
    isLiveMeeting,
    isFreeTierMeeting,
    isMeetingHost,
    isGussedMeetingPlatformSingleStream,
    isMultiStreamMeeting,
    isDownloadRestrictedMeeting,
    supportedLanguages,
    subscribeMeetingChanges,
    unsubscribeMeetingChanges,
    fetchCategories,
    categoryOptions,
  };
}
