import type Stripe from "stripe";
import type { Database } from "~/types/database.types";
import type { SupabaseTable } from "~/types/table.types";
import type { TierValue } from "~/utils/constants";

export function useUser() {
  const supabase = useSupabaseClient<Database>();

  const { $amplitude, $datadogRum, $http } = useNuxtApp();

  const user = useState<SupabaseTable.SupabaseUser | null>(() => null);
  watch(user, (newValue) => {
    if (!newValue) {
      $amplitude?.reset();
      $datadogRum?.clearUser();
    } else {
      $amplitude?.setUserId(newValue.email);
      $datadogRum?.setUser({
        id: newValue.id,
        email: newValue.email,
        name: getFullName.value,
      });
    }
  });

  const stripeCustomer = useState<Stripe.Customer | null>(() => null);

  const ipAddressWhitelist = useState<string[]>(() => []);
  const credits = useState<{
    [tier in TierValue]?: {
      availableMinutes: number;
      refundableMinutes: number;
    };
  }>(() => ({}));
  const dayPlans = useState<{ [id: string]: SupabaseTable.DayPlan }>(
    () => ({}),
  );
  const subscriptions = useState<{
    [tier in TierValue]?: SupabaseTable.Subscription;
  }>(() => ({}));

  const getFullName = computed(() => {
    return `${user.value?.user_metadata.first_name} ${user.value?.user_metadata.last_name}`;
  });
  const isExperimentalUser = computed(
    () =>
      user.value?.email?.includes("@xl8.ai") ||
      user.value?.user_metadata.is_experimental === true,
  );

  const lastUsedSpeakingLanguages = computed<string[]>({
    get() {
      return user.value?.user_metadata.last_used_speaking_languages || [];
    },
    async set(v: string[]) {
      const { error } = await supabase.auth.updateUser({
        data: { last_used_speaking_languages: v },
      });
      if (error) {
        console.error("failed to update last used speaking languages", error);
      } else {
        user.value!.user_metadata.last_used_speaking_languages = v;
      }
    },
  });

  const stripeCustomerId = computed(
    () => user.value?.user_metadata.stripe_customer_id,
  );
  const defaultPaymentMethodId = computed<string | null | undefined>(
    () =>
      stripeCustomer.value?.invoice_settings.default_payment_method as string,
  );

  const isAutoRechargeEnabled = computed({
    get() {
      return user.value?.user_metadata?.auto_recharge_enabled || false;
    },
    async set(v: boolean) {
      const { isLoading } = usePayment();

      try {
        isLoading.value = true;

        await $http(`/api/v1/users/auto-recharge`, {
          method: "PUT",
          body: { isAutoRechargeEnabled: v },
        });
        useToast().add({
          icon: "i-lucide-check",
          color: "emerald",
          title: `Your auto recharge option has ${v ? "enabled" : "disabled"}.`,
        });
        if (user.value) {
          user.value.user_metadata.auto_recharge_enabled = v;
        }
      } catch (err) {
        console.error("failed to update auto recharge", err);
        useToast().add({
          title: "Request Failed",
          color: "red",
        });
      } finally {
        isLoading.value = false;
      }
    },
  });

  const maxiumNumberOfLanguages = computed(
    () =>
      user.value?.user_metadata.maximum_number_of_languages ||
      UserDefault.MaxiumNumberOfLanguages,
  );

  const conferenceBronzeCredits = computed(() => {
    let minutes: number =
      credits.value[Tier.Conference.Bronze]?.availableMinutes || 0;

    if (minutes < 0) {
      minutes = 0;
    }

    return minutes;
  });
  const conferenceSilverCredits = computed(() => {
    let minutes: number =
      credits.value[Tier.Conference.Silver]?.availableMinutes || 0;

    if (minutes < 0) {
      minutes = 0;
    }

    return minutes;
  });

  const conferenceGoldDayPlans = computed<{
    [id: string]: SupabaseTable.DayPlan;
  }>(() =>
    Object.entries(dayPlans.value).reduce<{
      [id: string]: SupabaseTable.DayPlan;
    }>((acc, [key, value]) => {
      if (value.tier === Tier.Conference.Gold) {
        acc[key] = value;
      }
      return acc;
    }, {}),
  );
  const conferencePlatinumDayPlans = computed<{
    [id: string]: SupabaseTable.DayPlan;
  }>(() =>
    Object.entries(dayPlans.value).reduce<{
      [id: string]: SupabaseTable.DayPlan;
    }>((acc, [key, value]) => {
      if (value.tier === Tier.Conference.Platinum) {
        acc[key] = value;
      }
      return acc;
    }, {}),
  );
  const hasConferenceDayPlan = computed<boolean>(() => {
    return (
      Object.keys(conferenceGoldDayPlans.value).length > 0 ||
      Object.keys(conferencePlatinumDayPlans.value).length > 0
    );
  });
  const hasConferencePaidPlan = computed<boolean>(() => {
    return (
      Object.keys(conferenceGoldDayPlans.value).length > 0 ||
      Object.keys(conferencePlatinumDayPlans.value).length > 0 ||
      conferenceBronzeCredits.value > 0 ||
      conferenceSilverCredits.value > 0 ||
      isAutoRechargeEnabled.value === true
    );
  });

  const onlineMeetingMinutes = computed<number>(
    () => credits.value[Tier.OnlineMeeting.Prepaid]?.availableMinutes || 0,
  );
  const onlineMeetingRefundableMinutes = computed<number>(
    () => credits.value[Tier.OnlineMeeting.Prepaid]?.refundableMinutes || 0,
  );
  const onlineMeetingSubscription = computed<SupabaseTable.Subscription | null>(
    () => subscriptions.value[Tier.OnlineMeeting.Subscription] || null,
  );

  const onlineMeetingTier = computed<TierValue>(() => {
    if (onlineMeetingSubscription.value) {
      return Tier.OnlineMeeting.Subscription;
    } else if (onlineMeetingMinutes.value > 0 || isAutoRechargeEnabled.value) {
      return Tier.OnlineMeeting.Prepaid;
    } else {
      return Tier.OnlineMeeting.Free;
    }
  });

  async function fetchMyMeetings() {
    return $http<{
      meetings: { [meetingId: string]: SupabaseTable.Meeting };
      ongoingMeeting: SupabaseTable.Meeting | null;
    }>("/api/v1/users/meetings");
  }

  function fetchMyReplacements() {
    return $http("/api/v1/users/replacements");
  }

  return {
    user,
    stripeCustomer,
    stripeCustomerId,
    defaultPaymentMethodId,
    isAutoRechargeEnabled,
    maxiumNumberOfLanguages,
    credits,
    dayPlans,
    subscriptions,
    getFullName,
    lastUsedSpeakingLanguages,
    isExperimentalUser,
    ipAddressWhitelist,
    conferenceBronzeCredits,
    conferenceSilverCredits,
    conferenceGoldDayPlans,
    conferencePlatinumDayPlans,
    onlineMeetingSubscription,
    hasConferenceDayPlan,
    hasConferencePaidPlan,
    onlineMeetingMinutes,
    onlineMeetingRefundableMinutes,
    onlineMeetingTier,
    fetchMyMeetings,
    fetchMyReplacements,
  };
}
