import type { RealtimeChannel } from "@supabase/supabase-js";
import { BOT_STATUS_EXTENDED, MEETING_PLATFORM } from "~/types/recallBot.types";
import type { SupabaseTable } from "~/types/table.types";

export function useMeetingBot() {
  const supabase = useSupabaseClient();

  const bot = useState<SupabaseTable.MeetingBot | null>(() => null);

  const lastBotStatusChange = computed(() =>
    bot.value
      ? bot.value?.metadata?.status_changes?.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )?.[0]
      : null,
  );

  const isBotActivated = computed(() => {
    switch (lastBotStatusChange.value?.code) {
      case BOT_STATUS_EXTENDED.IN_CALL_RECORDING:
      case BOT_STATUS_EXTENDED.RECORDING_PERMISSION_ALLOWED:
      case BOT_STATUS_EXTENDED.CALL_ENDED:
      case BOT_STATUS_EXTENDED.DONE:
      case BOT_STATUS_EXTENDED.RECORDING_DONE:
      case BOT_STATUS_EXTENDED.MEDIA_EXPIRED:
        return true;
      default:
        return false;
    }
  });

  const isBotLeaved = computed(() => {
    switch (lastBotStatusChange.value?.code) {
      case BOT_STATUS_EXTENDED.CALL_ENDED:
      case BOT_STATUS_EXTENDED.DONE:
      case BOT_STATUS_EXTENDED.RECORDING_DONE:
      case BOT_STATUS_EXTENDED.MEDIA_EXPIRED:
        return true;
      default:
        return false;
    }
  });

  const isZoomMeeting = computed(() => {
    return bot.value?.metadata.meeting_url?.platform === MEETING_PLATFORM.ZOOM;
  });

  let supabaseChannel: RealtimeChannel | null = null;

  function subscribeMeetingBotChanges({ meetingId }: { meetingId: string }) {
    supabaseChannel = supabase
      .channel("MeetingBotChannel")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "MeetingBot",
          filter: `meeting_id=eq.${meetingId}`,
        },
        (payload) => {
          const meetingBot = payload.new as SupabaseTable.MeetingBot;
          bot.value = meetingBot;
        },
      )
      .subscribe();
  }

  function unsubscribeMeetingBotChanges() {
    supabaseChannel?.unsubscribe();
  }

  return {
    bot,
    isZoomMeeting,
    isBotLeaved,
    lastBotStatusChange,
    isBotActivated,
    subscribeMeetingBotChanges,
    unsubscribeMeetingBotChanges,
  };
}
