const DefaultMainLanguage = "en";
const DefaultSpeakingLanguages: string[] = ["en", "es"];

export function useSupportedLanguages() {
  const availableLanguages = useState<string[]>(() => []);
  const humanReadableLanguages = useState<Record<string, string>>(() => ({}));

  const mainLanguageCookie = useCookie<string>("mainLanguage", {
    default: () => DefaultMainLanguage,
  });
  const mainLanguage = computed({
    get: () => {
      return availableLanguages.value.includes(mainLanguageCookie.value)
        ? mainLanguageCookie.value
        : DefaultMainLanguage;
    },
    set: (v) => (mainLanguageCookie.value = v),
  });

  const speakingLanguagesCookie = useCookie<string[] | undefined>(
    "speakingLanguages",
  );

  const speakingLanguages = computed<string[]>({
    get: () => {
      if (speakingLanguagesCookie.value?.length) {
        return speakingLanguagesCookie.value.filter((i) =>
          availableLanguages.value.includes(i),
        );
      }

      return DefaultSpeakingLanguages;
    },
    set: (v) => {
      speakingLanguagesCookie.value = v;
    },
  });

  const lastUsedLanguagesCookie = useCookie<string[] | undefined>(
    "lastUsedLanguages",
  );
  const lastUsedLanguages = computed<string[]>({
    get: () => {
      if (!lastUsedLanguagesCookie.value?.length) {
        return DefaultSpeakingLanguages;
      }

      return lastUsedLanguagesCookie.value?.filter((i) =>
        availableLanguages.value.includes(i),
      );
    },
    set: (v) => (lastUsedLanguagesCookie.value = v),
  });

  const numberOfSupportedLanguages = computed(
    () => availableLanguages.value.length,
  );
  const supportedLanguages = computed(() => {
    const languages = availableLanguages.value.map((id) => ({
      id,
      label: humanReadableLanguages.value[id] || id,
      disabled: !humanReadableLanguages.value[id],
    }));

    const priorityLanguageIds = ["en", "es", "es-419", "ko"];

    const prioritySet = new Set(priorityLanguageIds);
    const priorityLanguages = languages.filter((lang) =>
      prioritySet.has(lang.id),
    );
    const nonPriorityLanguages = languages.filter(
      (lang) => !prioritySet.has(lang.id),
    );

    nonPriorityLanguages.sort((a, b) => a.label.localeCompare(b.label));

    const sortedLanguages = [...priorityLanguages, ...nonPriorityLanguages];

    return sortedLanguages;
  });

  const TOP_LANGUAGES_BY_CONTRY = {
    ko: ["en", "ja"],
    en: ["es", "ko"],
    "zh-Han": ["en", "es"],
    ja: ["en", "ko"],
  };

  const BROWSER_LANGUAGES_FALLBACK = "en";
  const BROWSER_LANGUAGES = availableLanguages.value.includes(
    navigator?.language.substring(0, 2),
  )
    ? navigator?.language.substring(0, 2)
    : BROWSER_LANGUAGES_FALLBACK;

  const mostPopularLanguagesByCountry = TOP_LANGUAGES_BY_CONTRY?.[
    BROWSER_LANGUAGES as keyof typeof TOP_LANGUAGES_BY_CONTRY
  ] || ["es", "ko"];

  async function fetchAvailableLanguages() {
    try {
      const resp = await useNuxtApp().$http("/api/v1/languages");

      humanReadableLanguages.value = resp.humanReadableLanguages;
      availableLanguages.value = resp.languages.filter((i) => {
        switch (i) {
          case "be": // Belarusian doesn't supported multilingual STT for now.
            return false;
          default:
            return true;
        }
      });

      return;
    } catch (err) {
      console.error("failed to fetch xl8 languages", err);
    }
  }

  return {
    speakingLanguages,
    lastUsedLanguages,
    availableLanguages,
    mainLanguage,
    BROWSER_LANGUAGES,
    humanReadableLanguages,
    numberOfSupportedLanguages,
    supportedLanguages,
    fetchAvailableLanguages,
    mostPopularLanguagesByCountry,
  };
}
