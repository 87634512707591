import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as history4sd9PNLmeiMeta } from "/vercel/path0/pages/[slug]/meetings/history.vue?macro=true";
import { default as indexnLyoWfD1ldMeta } from "/vercel/path0/pages/[slug]/meetings/index.vue?macro=true";
import { default as indexaxs0hh0hl1Meta } from "/vercel/path0/pages/[slug]/members/index.vue?macro=true";
import { default as indexIO2YkQVI7FMeta } from "/vercel/path0/pages/[slug]/settings/index.vue?macro=true";
import { default as _91_46_46_46slug_93QvcqcC3xvKMeta } from "/vercel/path0/pages/blog/[...slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as editWqjPbQgBdBMeta } from "/vercel/path0/pages/conferences/[conferenceId]/edit.vue?macro=true";
import { default as index4V4BpIJlzvMeta } from "/vercel/path0/pages/conferences/[conferenceId]/index.vue?macro=true";
import { default as index9IM577Muw0Meta } from "/vercel/path0/pages/conferences/index.vue?macro=true";
import { default as listAh2eABcJ8IMeta } from "/vercel/path0/pages/conferences/list.vue?macro=true";
import { default as newEF0Wd1wZzIMeta } from "/vercel/path0/pages/conferences/new.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as forgot487FlM3HxjMeta } from "/vercel/path0/pages/forgot.vue?macro=true";
import { default as _91glossaryId_93CpY7XGhXCzMeta } from "/vercel/path0/pages/glossaries/[glossaryId].vue?macro=true";
import { default as indexsZc9dct0NCMeta } from "/vercel/path0/pages/glossaries/index.vue?macro=true";
import { default as glossariesZ0J117ugb1Meta } from "/vercel/path0/pages/glossaries.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexGVhVGLzGJbMeta } from "/vercel/path0/pages/ko/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as _91domain_93lLjLEkZmNAMeta } from "/vercel/path0/pages/login/sso/[domain].vue?macro=true";
import { default as _91meetingId_937aivB9vov3Meta } from "/vercel/path0/pages/meetings/[meetingId].vue?macro=true";
import { default as historyrTlqcIdjUHMeta } from "/vercel/path0/pages/meetings/history.vue?macro=true";
import { default as indexWevLMfGMnZMeta } from "/vercel/path0/pages/meetings/index.vue?macro=true";
import { default as _91meetingId_93Qo1zuzz087Meta } from "/vercel/path0/pages/meetings/zoom/[meetingId].vue?macro=true";
import { default as indexlA85nUMhEvMeta } from "/vercel/path0/pages/meetings/zoom/index.vue?macro=true";
import { default as newb5wW3Vq40sMeta } from "/vercel/path0/pages/new.vue?macro=true";
import { default as historyP3dd5p8nkaMeta } from "/vercel/path0/pages/payment/history.vue?macro=true";
import { default as successyZ3B4PpEueMeta } from "/vercel/path0/pages/payment/success.vue?macro=true";
import { default as conference11BBBdW0mrMeta } from "/vercel/path0/pages/pricing/conference.vue?macro=true";
import { default as indexIwBpLtCJW6Meta } from "/vercel/path0/pages/pricing/index.vue?macro=true";
import { default as online_meetingJ25OuXOlSeMeta } from "/vercel/path0/pages/pricing/online_meeting.vue?macro=true";
import { default as indexa7SLYrUpNFMeta } from "/vercel/path0/pages/privacy/index.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as recalldemoyFPdokh0REMeta } from "/vercel/path0/pages/recalldemo.vue?macro=true";
import { default as resetjSuUNxfTnwMeta } from "/vercel/path0/pages/reset.vue?macro=true";
import { default as controllersXCfxom2rbMeta } from "/vercel/path0/pages/sessions/[sessionId]/controller.vue?macro=true";
import { default as indexYzNHiBktIRMeta } from "/vercel/path0/pages/sessions/[sessionId]/index.vue?macro=true";
import { default as newa4EwjS2d7oMeta } from "/vercel/path0/pages/sessions/[sessionId]/new.vue?macro=true";
import { default as history3UpIVioOoDMeta } from "/vercel/path0/pages/sessions/history.vue?macro=true";
import { default as settingk2C5Z4r5zOMeta } from "/vercel/path0/pages/setting.vue?macro=true";
import { default as smtrUGwtcIidGMeta } from "/vercel/path0/pages/smt.vue?macro=true";
import { default as indexyZF5ray81RMeta } from "/vercel/path0/pages/tos/index.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "slug-meetings-history",
    path: "/:slug()/meetings/history",
    meta: history4sd9PNLmeiMeta || {},
    component: () => import("/vercel/path0/pages/[slug]/meetings/history.vue")
  },
  {
    name: "slug-meetings",
    path: "/:slug()/meetings",
    meta: indexnLyoWfD1ldMeta || {},
    component: () => import("/vercel/path0/pages/[slug]/meetings/index.vue")
  },
  {
    name: "slug-members",
    path: "/:slug()/members",
    meta: indexaxs0hh0hl1Meta || {},
    component: () => import("/vercel/path0/pages/[slug]/members/index.vue")
  },
  {
    name: "slug-settings",
    path: "/:slug()/settings",
    meta: indexIO2YkQVI7FMeta || {},
    component: () => import("/vercel/path0/pages/[slug]/settings/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93QvcqcC3xvKMeta || {},
    component: () => import("/vercel/path0/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "conferences-conferenceId-edit",
    path: "/conferences/:conferenceId()/edit",
    meta: editWqjPbQgBdBMeta || {},
    component: () => import("/vercel/path0/pages/conferences/[conferenceId]/edit.vue")
  },
  {
    name: "conferences-conferenceId",
    path: "/conferences/:conferenceId()",
    meta: index4V4BpIJlzvMeta || {},
    component: () => import("/vercel/path0/pages/conferences/[conferenceId]/index.vue")
  },
  {
    name: "conferences",
    path: "/conferences",
    meta: index9IM577Muw0Meta || {},
    component: () => import("/vercel/path0/pages/conferences/index.vue")
  },
  {
    name: "conferences-list",
    path: "/conferences/list",
    meta: listAh2eABcJ8IMeta || {},
    component: () => import("/vercel/path0/pages/conferences/list.vue")
  },
  {
    name: "conferences-new",
    path: "/conferences/new",
    meta: newEF0Wd1wZzIMeta || {},
    component: () => import("/vercel/path0/pages/conferences/new.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexDBbnZWdxZ3Meta || {},
    component: () => import("/vercel/path0/pages/contact/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgot487FlM3HxjMeta || {},
    component: () => import("/vercel/path0/pages/forgot.vue")
  },
  {
    name: glossariesZ0J117ugb1Meta?.name,
    path: "/glossaries",
    component: () => import("/vercel/path0/pages/glossaries.vue"),
    children: [
  {
    name: "glossaries-glossaryId",
    path: ":glossaryId()",
    component: () => import("/vercel/path0/pages/glossaries/[glossaryId].vue")
  },
  {
    name: "glossaries",
    path: "",
    component: () => import("/vercel/path0/pages/glossaries/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "ko",
    path: "/ko",
    meta: indexGVhVGLzGJbMeta || {},
    component: () => import("/vercel/path0/pages/ko/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexoOBDY93e9EMeta || {},
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "login-sso-domain",
    path: "/login/sso/:domain()",
    meta: _91domain_93lLjLEkZmNAMeta || {},
    component: () => import("/vercel/path0/pages/login/sso/[domain].vue")
  },
  {
    name: "meetings-meetingId",
    path: "/meetings/:meetingId()",
    meta: _91meetingId_937aivB9vov3Meta || {},
    component: () => import("/vercel/path0/pages/meetings/[meetingId].vue")
  },
  {
    name: "meetings-history",
    path: "/meetings/history",
    meta: historyrTlqcIdjUHMeta || {},
    component: () => import("/vercel/path0/pages/meetings/history.vue")
  },
  {
    name: "meetings",
    path: "/meetings",
    meta: indexWevLMfGMnZMeta || {},
    component: () => import("/vercel/path0/pages/meetings/index.vue")
  },
  {
    name: "meetings-zoom-meetingId",
    path: "/meetings/zoom/:meetingId()",
    meta: _91meetingId_93Qo1zuzz087Meta || {},
    component: () => import("/vercel/path0/pages/meetings/zoom/[meetingId].vue")
  },
  {
    name: "meetings-zoom",
    path: "/meetings/zoom",
    meta: indexlA85nUMhEvMeta || {},
    component: () => import("/vercel/path0/pages/meetings/zoom/index.vue")
  },
  {
    name: "new",
    path: "/new",
    meta: newb5wW3Vq40sMeta || {},
    component: () => import("/vercel/path0/pages/new.vue")
  },
  {
    name: "payment-history",
    path: "/payment/history",
    meta: historyP3dd5p8nkaMeta || {},
    component: () => import("/vercel/path0/pages/payment/history.vue")
  },
  {
    name: "payment-success",
    path: "/payment/success",
    component: () => import("/vercel/path0/pages/payment/success.vue")
  },
  {
    name: "pricing-conference",
    path: "/pricing/conference",
    component: () => import("/vercel/path0/pages/pricing/conference.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/vercel/path0/pages/pricing/index.vue")
  },
  {
    name: "pricing-online_meeting",
    path: "/pricing/online_meeting",
    component: () => import("/vercel/path0/pages/pricing/online_meeting.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/vercel/path0/pages/privacy/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilekxSCn5bMpQMeta || {},
    component: () => import("/vercel/path0/pages/profile.vue")
  },
  {
    name: "recalldemo",
    path: "/recalldemo",
    meta: recalldemoyFPdokh0REMeta || {},
    component: () => import("/vercel/path0/pages/recalldemo.vue")
  },
  {
    name: "reset",
    path: "/reset",
    meta: resetjSuUNxfTnwMeta || {},
    component: () => import("/vercel/path0/pages/reset.vue")
  },
  {
    name: "sessions-sessionId-controller",
    path: "/sessions/:sessionId()/controller",
    meta: controllersXCfxom2rbMeta || {},
    component: () => import("/vercel/path0/pages/sessions/[sessionId]/controller.vue")
  },
  {
    name: "sessions-sessionId",
    path: "/sessions/:sessionId()",
    meta: indexYzNHiBktIRMeta || {},
    component: () => import("/vercel/path0/pages/sessions/[sessionId]/index.vue")
  },
  {
    name: "sessions-sessionId-new",
    path: "/sessions/:sessionId()/new",
    meta: newa4EwjS2d7oMeta || {},
    component: () => import("/vercel/path0/pages/sessions/[sessionId]/new.vue")
  },
  {
    name: "sessions-history",
    path: "/sessions/history",
    meta: history3UpIVioOoDMeta || {},
    component: () => import("/vercel/path0/pages/sessions/history.vue")
  },
  {
    name: "setting",
    path: "/setting",
    meta: settingk2C5Z4r5zOMeta || {},
    component: () => import("/vercel/path0/pages/setting.vue")
  },
  {
    name: "smt",
    path: "/smt",
    meta: smtrUGwtcIidGMeta || {},
    component: () => import("/vercel/path0/pages/smt.vue")
  },
  {
    name: "tos",
    path: "/tos",
    component: () => import("/vercel/path0/pages/tos/index.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/meetings/open",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/meeting/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/conference/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/session/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  }
]