export function usePayment() {
  const { $http } = useNuxtApp();
  const isLoading = useState<boolean>(() => false);

  async function openCustomerPortalUrl() {
    try {
      isLoading.value = true;
      const { url } = await $http("/api/v1/payment/customer_portal");
      window.open(url);
    } catch (err) {
      const url = useRuntimeConfig().public.STRIPE_CUSTOMER_PORTAL_URL;
      window.open(url);
    } finally {
      isLoading.value = false;
      useEventTracker().trackEvent(TRACKING_EVENT.PAYMENT.OPEN_CUSTOMER_PORTAL);
    }
  }

  async function createCheckoutSession({
    lookupKey,
    quantity,
    isAutoRechargeEnabled,
  }: {
    lookupKey: string;
    quantity: number;
    isAutoRechargeEnabled?: boolean;
  }) {
    try {
      await useAuth().openLoginModalIfNotLoggedIn();

      isLoading.value = true;

      const route = useRouter()
        .getRoutes()
        .find((i) => i.name === ROUTE_NAMES.PAYMENT_SUCCESS);
      if (!route) {
        throw new Error("Checkout route not found");
      }

      const { session } = await $http("/api/v1/payment/checkout_session", {
        method: "POST",
        body: {
          locale: navigator.language,
          lookupKey,
          quantity,
          successUrl: location.origin + route?.path,
          cancelUrl: location.href,
          isAutoRechargeEnabled,
        },
      });

      if (session.url) {
        await universalNavigateTo({ url: session.url });
      }
    } catch (err) {
      console.error("failed to checkout session", err);

      useToast().add({
        color: "red",
        icon: "i-lucide-alert-circle",
        title: "Checkout Failed",
        description: "Sorry, please try it again later.",
      });

      isLoading.value = false;
    }
  }

  async function refundPrepaidMinutes(tier: TierValue) {
    try {
      isLoading.value = true;

      const resp = await $http(`/api/v1/users/refund_credit`, {
        method: "POST",
        body: { tier },
      });

      return resp;
    } catch (err) {
      useToast().add({
        icon: "i-lucide-alert-circle",
        title: "Failed to Refund",
        color: "red",
        description: "Sorry, please try it again later.",
      });
      console.error(err);
      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    refundPrepaidMinutes,
    createCheckoutSession,
    openCustomerPortalUrl,
  };
}
