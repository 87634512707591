// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export function useFreshdesk() {
  const { user, getFullName } = useUser();
  const { trackEvent, TRACKING_EVENT } = useEventTracker();

  const WidgetId = 150000004514;
  const GroupId = 150000390067; // EventCAT Team
  const ServiceId = "ticketForm";

  function init() {
    useHead({
      script: [
        {
          async: true,
          defer: true,
          type: "text/javascript",
          src: `https://widget.freshworks.com/widgets/${WidgetId}.js`,
        },
      ],
    });

    window.fwSettings = {
      widget_id: WidgetId,
    };

    const n = function () {
      // eslint-disable-next-line prefer-rest-params
      n.q.push(arguments);
    };

    (n.q = []), (window.FreshworksWidget = n);

    window.FreshworksWidget("prefill", "ticketForm", {
      group_id: GroupId,
    });

    window.FreshworksWidget("hide", "launcher");
  }

  function openContactUsForm(options?: { subject?: string }) {
    if (window?.FreshworksWidget) {
      if (user.value) {
        window.FreshworksWidget("identify", ServiceId, {
          name: getFullName.value,
          email: user.value.email,
        });
      }

      const DESCRIPTION_PREFILL = `URL: ${useRoute().fullPath}\n\n`;
      const START_OF_DESCRIPTION = `Please describe any information below that can help us to solve your problem.\n----------------------------------------\n\n`;

      window.FreshworksWidget("prefill", "ticketForm", {
        subject: options?.subject || "",
        description: `${DESCRIPTION_PREFILL}${START_OF_DESCRIPTION}`,
      });

      window.FreshworksWidget("open", ServiceId, { group_id: GroupId }, {});

      trackEvent(TRACKING_EVENT.CONTACT_US.OPENED);
    }
  }

  function openHelpWidget() {
    if (window?.FreshworksWidget) {
      window.FreshworksWidget("open");
    }
  }

  return { init, openContactUsForm, openHelpWidget };
}
