export const ROUTE_NAMES = {
  HOME: "index",
  HOME_KOREAN: "ko",
  BLOG: "blog",
  PRIVACY: "privacy",
  TOS: "tos",
  NEW: "new",
  LOGIN: "login",
  RESET: "reset",
  FORGOT: "forgot",
  TRANSACTIONS: "transactions",
  CONFIRM: "confirm",
  PROFILE: "profile",
  PAYMENT: "pricing",
  PAYMENT_ONLINE_MEETING: "pricing-online_meeting",
  PAYMENT_CONFERENCE: "pricing-conference",
  PAYMENT_SUCCESS: "payment-success",
  PAYMENT_HISTORY: "payment-history",
  MEETING_OPEN: "meetings",
  MEETING_ZOOM: "meetings-zoom",
  MEETING_DETAIL: "meetings-meetingId",
  ZOOM_MEETING_DETAIL: "meetings-zoom-meetingId",
  MEETING_HISTORY: "meetings-history",
  CONFERENCE: "conferences",
  CONFERENCE_NEW: "conferences-new",
  CONFERENCE_LIST: "conferences-list",
  CONFERENCE_DETAIL: "conferences-conferenceId",
  CONFERENCE_DETAIL_EDIT: "conferences-conferenceId-edit",
  SESSION_HISTORY: "sessions-history",
  SESSION_DETAIL: "sessions-sessionId",
  SESSION_DETAIL_CONTROLLER: "sessions-sessionId-controller",
  SLUG_MEMBERS: "slug-members",
  SLUG_MEETINGS: "slug-meetings",
  SLUG_SETTINGS: "slug-settings",
  SLUG_MEETINGS_HISTORY: "slug-meetings-history",
  GLOSSARY: "glossaries",
  GLOSSARY_DETAIL: "glossaries-glossaryId",
} as const;
