import type { SupabaseTable } from "~/types/table.types";

export function useOrganizaiton() {
  const { $http } = useNuxtApp();
  const { organizationId } = useMeeting();
  const isLoading = useState<boolean>(() => false);
  const organizations = useState<SupabaseTable.Organization[]>(() => []);

  const currentOrganization = computed(() => {
    return organizations.value.find((i) => i.id === organizationId.value);
  });

  function fetchOrganization(slug: string) {
    return $http(`/api/v1/organizations/${slug}`);
  }

  function fetchOrganizationMeetings(slug: string) {
    return $http<{ meetings: SupabaseTable.Meeting[] }>(
      `/api/v1/organizations/${slug}/meetings`,
    );
  }

  async function upateIpAddressWhitelist(slug: string, ipAddresses: string[]) {
    try {
      isLoading.value = true;

      const resp = await $http(
        `/api/v1/organizations/${slug}/ip_address_whitelist`,
        {
          method: "PUT",
          body: { ipAddresses },
        },
      );

      useToast().add({
        icon: "i-lucide-check",
        color: "emerald",
        title: "Your ip address whitelist has been updated.",
      });

      return resp;
    } catch (err) {
      console.error("failed to update ipaddress whitelist", err);
      useToast().add({
        title: "Request Failed",
        color: "red",
        description: "Please check you input.",
      });
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    organizations,
    fetchOrganization,
    fetchOrganizationMeetings,
    upateIpAddressWhitelist,
    currentOrganization,
  };
}
