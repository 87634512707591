export function useBrowserControl() {
  function disableBrowserTranslation() {
    useHead({
      htmlAttrs: {
        translate: "no",
      },
      meta: [
        {
          name: "google",
          content: "notranslate",
        },
      ],
    });
  }

  return { disableBrowserTranslation };
}
