import type { SupabaseTable } from "~/types/table.types";

export function useFeatureFlag() {
  const { $http } = useNuxtApp();

  const isLoaded = useState<boolean>(() => false);
  const services = useState<SupabaseTable.Service[]>(() => []);
  const serviceAvailablilty = useState<{
    conference: boolean;
    onlineMeeting: boolean;
  }>(() => ({
    conference: false,
    onlineMeeting: false,
  }));

  const superHeaders = useState<{
    [pageUrl: string]: {
      enabled: boolean;
      title?: string;
      description?: string;
      targetUrl?: string;
    };
  }>(() => ({}));

  function fetchStatus() {
    return $http(`/api/v1/status`).then((resp) => {
      if (resp.status) {
        serviceAvailablilty.value.onlineMeeting =
          resp.status.onlineMeeting || false;
      }

      if (resp.superHeaders) {
        superHeaders.value = resp.superHeaders;
      }

      if (resp.services) {
        services.value = resp.services;
      }

      isLoaded.value = true;
    });
  }

  return { isLoaded, services, serviceAvailablilty, superHeaders, fetchStatus };
}
