export const MEETING_PLATFORM = {
  ZOOM: "zoom",
  GOOGLE_MEET: "google_meet",
  MS_TEAMS: "microsoft_teams",
  MS_TEAMS_LIVE: "microsoft_teams_live",
} as const;

export const RECALL_PARTICIPANT_WEBHOOK_EVENT = {
  PARTICIPANT_JOIN: "bot.participant_join",
  PARTICIPANT_LEAVE: "bot.participant_leave",
  ACTIVE_SPEAKER_NOTIFY: "bot.active_speaker_notify",
} as const;

export const RECALL_FATAL_ERROR_REAONS = {
  ZOOM_REGISTRATION_REQUIRED: "zoom_registration_required",
  BOT_KICKED_FROM_CALL: "bot_kicked_from_call",
  MEETING_LINK_EXPIRED: "meeting_link_expired",
  MEETING_LOCKED: "meeting_locked",
  MEETING_LINK_INVALID: "meeting_link_invalid",
  MEETING_FULL: "meeting_full",
} as const;

export const RECORDING_PERMISSION_DENIED_SUB_CODES = {
  ZOOM_LOCAL_RECORDING_DISABLED: "zoom_local_recording_disabled",
  ZOOM_LOCAL_RECORDING_REQUEST_DISABLED:
    "zoom_local_recording_request_disabled",
  ZOOM_LOCAL_RECORDING_REQUEST_DISABLED_BY_HOST:
    "zoom_local_recording_request_disabled_by_host",
  ZOOM_BOT_IN_WAITING_ROOM: "zoom_bot_in_waiting_room",
  ZOOM_HOST_NOT_PRESENT: "zoom_host_not_present",
  ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST:
    "zoom_local_recording_request_denied_by_host",
  ZOOM_LOCAL_RECORDING_DENIED: "zoom_local_recording_denied",
  ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED:
    "zoom_local_recording_grant_not_supported",
  ZOOM_SDK_KEY_BLOCKED_BY_HOST_ADMIN: "zoom_sdk_key_blocked_by_host_admin",
} as const;

export const BOT_STATUS = {
  READY: "ready",
  JOINING_CALL: "joining_call",
  IN_WAITING_ROOM: "in_waiting_room",
  PARTICIPANT_IN_WAITING_ROOM: "participant_in_waiting_room",
  IN_CALL_NOT_RECORDING: "in_call_not_recording",
  RECORDING_PERMISSION_DENIED: "recording_permission_denied",
  RECORDING_PERMISSION_ALLOWED: "recording_permission_allowed",
  IN_CALL_RECORDING: "in_call_recording",
  CALL_ENDED: "call_ended",
  RECORDING_DONE: "recording_done",
  DONE: "done",
  FATAL: "fatal",
  ANALYSIS_DONE: "analysis_done",
  ANALYSIS_FAILED: "analysis_failed",
  MEDIA_EXPIRED: "media_expired",
} as const;

const EXTEND_STATUS = {
  NOT_SUPPORTED: "not_supported",
};

export const BOT_STATUS_EXTENDED = Object.assign(BOT_STATUS, EXTEND_STATUS);

export type BotStatusCode = ValueOf<typeof BOT_STATUS>;

export type MeetingPlatform = ValueOf<typeof MEETING_PLATFORM>;

export type RecallFatalErrorReason = ValueOf<typeof RECALL_FATAL_ERROR_REAONS>;

export type RecordingPermissionDeniedSubCode = ValueOf<
  typeof RECORDING_PERMISSION_DENIED_SUB_CODES
>;
