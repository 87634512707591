export const TRACKING_EVENT = {
  INVITE_BOT: "Invite Bot",
  SWTICH_CONVERSATION_IN: "Switch Conversation In",
  SWITCHED_SOURCE_LANGUAGE: "Switched Source Language",
  ENABLE_AUTO_SCROLL: "Enable Auto Scroll",
  CLICK_LIVE_TEXT_READING: "Click Live Text Reading",
  SHORT_URL_GENERATED: "Short URL Generated",
  TRANSCRIPT: {
    SHARED: "Shared Transcript",
    COPIED_TO_CLIPBOARD: "Copied Transcript to Clipboard",
  },
  ONBOARDING_TOUR: {
    STARTED: "Onboarding Tour - Started",
    CLICKED_NEXT: "Onboarding Tour - Clicked Next",
    CLICKED_PREV: "Onboarding Tour - Clicked Previous",
    CLOSED: "Onboarding Tour - Closed",
  },
  CONTACT_US: {
    OPENED: "Opened Contact Us Form",
  },
  AUTH: {
    SIGNED_OUT: "Signed Out",
    SIGNED_UP: "Signed Up",
    SIGNED_UP_FAILED: "Signed Up Failed",
    CONFIRMED_SIGN_UP: "Confirmed Sign Up",
    CONFIRM_SIGN_UP_FAILED: "Confirm Sign Up Failed",
    SENT_OTP: "Sent OTP",
    SEND_OTP_FAILED: "Send OTP Failed",
    SIGNED_IN: "Signed In",
    SIGNING_IN_FAILED: "Signing In Failed",
    REQUESTED_PASSWORD_RESET: "Requested Reset Password",
    REQUEST_PASSWORD_RESET_FAILED: "Request Reset Password Failed",
    RESET_PASSWORD: "Reset Password",
    RESET_PASSWORD_FAILED: "Reset Password Failed",
    RESENT_OTP: "Resent OTP",
    ACCOUNT_DELETED: "Account Deleted",
  },
  PAYMENT: {
    OPEN_CUSTOMER_PORTAL: "Opened Customer Portal",
  },
  MEETING: {
    JOINED_AS_GUEST: "Joined as Guest",
    MUTED: "Muted Participant",
    REMOVE_BOT: "Removed Bot",
    SYNC_MEETING_MANUALLY: "Sync Meeting Manually",
    DOWNLOADED_TRANSCRIPT: "Downloaded Transcript",
    DOWNLOADED_TRANSCRIPT_TO_VTT: "Downloaded Transcript to VTT",
    DOWNLOADED_RECORDING: "Downloaded Recording",
    CLICKED_PROMOTE_BUTTON: "Clicked Promote Button",
  },
  CONFERENCE: {
    CREATED: "Conference Created",
    CREATE_FAILED: "Conference Create Failed",
    UPDATED: "Conference Updated",
    UPDATE_FAILED: "Conference Update Failed",
    DELETED: "Conference Deleted",
    DELETE_FAILED: "Conference Delete Failed",
  },
  SESSION: {
    OPENED_INSTANT_CREATE_FORM: "Instant Session Create Form Opened",
    CREATED: "Session Created",
    UPDATED: "Session Updated",
    DELETED: "Session Deleted",
    STARTED: "Session Stared",
    CLOSED: "Session Closed",
    DELETED_TRANSCRIPT: "Deleted Session Transcript",
    DOWNLOADED_TRANSCRIPT: "Downloaded Session Transcript",
    CREATED_SPEAKER: "Created Session Speaker",
    DELETED_SPEAKER: "Deleted Session Speaker",
    SET_MAIN_LANGUAGE: "Set Main Language",
    SET_SECOND_LANGUAGE: "Set Second Language",
    Demo: {
      STARTED: "Session Demo Started",
      CLOSED: "Session Demo Closed",
      CLOSED_BY_TIMEOUT: "Session Demo Closed by Timeout",
    },
  },
} as const;

export function useEventTracker() {
  const { user } = useUser();

  function trackEvent(
    eventName: ValueOf<keyof typeof TRACKING_EVENT>,
    props?: Record<string, unknown>,
  ) {
    const { $amplitude } = useNuxtApp();

    $amplitude?.track(`EventCAT: ${eventName}` as string, {
      ...props,
      email: user.value?.email,
    });
  }

  return { trackEvent, TRACKING_EVENT };
}
