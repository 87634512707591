import type ZoomSdk from "@zoom/appssdk";
import type { ConfigOptions, ConfigResponse } from "@zoom/appssdk";

let zoomSdk: typeof ZoomSdk;

export function useZoomSdk() {
  const { form } = useMeeting();

  const config = useState<ConfigResponse>();
  const isZoomApp = useState<boolean>(() => false);
  const loading = useState<boolean>(() => true);

  const configOptions: ConfigOptions = {
    version: "0.16.0",
    capabilities: ["openUrl", "getMeetingJoinUrl"],
  };

  function openUrl(url: string) {
    zoomSdk?.openUrl({ url });
  }

  async function initZoomSdk() {
    loading.value = true;
    isZoomApp.value = true;

    try {
      zoomSdk = (await import("@zoom/appssdk")).default;
      config.value = await zoomSdk.config(configOptions);

      const { role } = await zoomSdk.getUserContext();

      if (role === "host") {
        const meetingJoinUrl = await zoomSdk.getMeetingJoinUrl();
        form.value.meetingUrl = meetingJoinUrl.joinUrl;
      }
    } finally {
      loading.value = false;
    }
  }
  return { openUrl, initZoomSdk, isZoomApp, loading };
}
