export default defineNuxtRouteMiddleware(async () => {
  try {
    const { isLoaded, fetchStatus } = useFeatureFlag();
    if (isLoaded.value) {
      return;
    }

    await fetchStatus();
  } catch (err) {
    console.error("failed to fetch eventcat app status.", err);
  }
});
