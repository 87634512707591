import { MEETING_PLATFORM } from "~/types/recallBot.types";

export const Constants = {
  LOCAL_STORAGE_KEYS: {
    SOURCE_LANGUAGE: "sourceLanguages",
    TARGET_LANGUAGES: "targetLanguages",
    USER_EMAIL: "userEmail",
    LAST_USED_TARGET_LANGUAGES: "usedTargetLanguages",
  } as const,
  DEFAULT_SOURCE_LANGUAGES: ["en", "es"],
  USER_MANUAL_LINKS: {
    ZOOM: {
      href: "https://support.xl8.ai/support/solutions/articles/150000174690-eventcat-user-guide-zoom-for-online-calls-",
      label: "Zoom",
    },
    ZOOM_WEBINAR: {
      href: "https://support.xl8.ai/support/solutions/articles/150000175186-how-to-use-eventcat-onlinemeeting-with-zoom-webinar",
      label: "Zoom Webinar",
    },
    GOOGLE_MEET: {
      href: "https://support.xl8.ai/support/solutions/articles/150000174695-eventcat-user-guide-google-meet-for-seminars-",
      label: "Google Meet",
    },
    MS_TEAMS: {
      href: "https://support.xl8.ai/a/solutions/articles/150000176084",
      label: "Microsoft Teams",
    },
  },
  SERVICE: {
    CONFERENCE: "conference",
    ONLINE_MEETING: "online_meeting",
  },
  Meeting: {
    MinutesOfFreeTier: 20,
  },
  Conference: {
    MinutesOfFreeTier: 10,
  },
};

export const Service = {
  Conference: "conference",
  OnlineMeeting: "online_meeting",
} as const;

export const Meeting = {
  MinutesOfFreeTier: 20,
  AllowedMeetingUrlFormat: {
    [MEETING_PLATFORM.ZOOM]: {
      title: "Allowed Meeting URL Format for Zoom",
      content: [
        "https://zoom.us/j/[meetingId]",
        "https://zoom.us/w/[meetingId]",
        "https://company.zoom.us/j/[meetingId]",
      ],
      patterns: [
        /^https?:\/\/([\w-]+\.)*zoom\.(us|com)\/(j|w)\/(\d+)(\?.*)?(#.*)?$/,
        /^https?:\/\/([\w-]+\.)*zoom\.(us|com)\/(my)\/(\w+)(\?.*)?(#.*)?$/,
      ],
    },
    [MEETING_PLATFORM.GOOGLE_MEET]: {
      title: "Allowed Meeting URL Format for Google Meet",
      content: [
        "https://meet.google.com/[meetingID]",
        "meet.google.com/[meetingID]",
      ],
      patterns: [/meet\.google\.com\/[a-z0-9-]+/],
    },
    [MEETING_PLATFORM.MS_TEAMS]: {
      title: "Allowed Meeting URL Format for Microsoft Teams",
      content: [
        "https://teams.microsoft.com/l/meetup-join/[meeting code]/[meeting identifier]",
      ],
      patterns: [/teams\.(microsoft|live)\.com/],
    },
  },
};

export const Conference = {
  MinutesOfFreeTier: 10,
  Tier: {
    Free: "conference_free",
    Bronze: "conference_prepaid_bronze",
    Silver: "conference_prepaid_silver",
    Gold: "conference_dayplan_gold",
    Platinum: "conference_dayplan_platinum",
  },
};

export const Tier = {
  OnlineMeeting: {
    Free: "onlineMeeting_free",
    Prepaid: "onlineMeeting_prepaid",
    Subscription: "onlineMeeting_subscription",
  },
  Conference: {
    Free: "conference_free",
    Bronze: "conference_prepaid_bronze",
    Silver: "conference_prepaid_silver",
    Gold: "conference_dayplan_gold",
    Platinum: "conference_dayplan_platinum",
  },
} as const;

export type TierValue =
  | (typeof Tier.OnlineMeeting)[keyof typeof Tier.OnlineMeeting]
  | (typeof Tier.Conference)[keyof typeof Tier.Conference];

export const SSO_PROVIDER = {
  OKTA: "okta",
} as const;

export const SPEAKER_COLOR = [
  "text-[#FFFFE0]",
  "text-[#FFB6C1]",
  "text-[#B0C4DE]",
  "text-[#FFDAB9]",
  "text-[#D8BFD8]",
  "text-[#FFD700]",
  "text-[#1E90FF]",
  "text-[#FFA500]",
  "text-[#FF4500]",
  "text-[#AFEEEE]",
  "text-[#E6E6FA]",
  "text-[#E0FFFF]",
  "text-[#DDA0DD]",
  "text-[#FFA07A]",
  "text-[#9370DB]",
  "text-[#FFDAB9]",
  "text-[#FA8072]",
  "text-[#FFFF00]",
  "text-[#FFD700]",
  "text-[#D2B48C]",
];

export const Color = {
  ChromaKeyBackgroundColor: "bg-[#00ff00]",
};
