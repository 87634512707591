import * as Amplitude from "@amplitude/analytics-browser";

type AmplitudeType = typeof Amplitude | null;

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();

  // this `amplitude` variable will be `undefined` in server side call, because this plugin is only injected on client side.
  let amplitude: AmplitudeType = null;

  if (import.meta.client) {
    Amplitude.init(runtimeConfig.public.AMPLITUDE_API_KEY, {
      defaultTracking: true,
      logLevel: Amplitude.Types.LogLevel.Warn,
    });
    amplitude = Amplitude;
  }

  return {
    provide: { amplitude: amplitude as AmplitudeType },
  };
});
