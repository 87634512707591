export default defineAppConfig({
  ui: {
    primary: "orange",
    container: {
      padding: "!px-5",
      constrained: "max-w-[1216px]",
    },
    notifications: {
      position: "top-0 right-0 bottom-auto",
    },
    formGroup: {
      container: "mt-1.5",
      label: {
        base: "text-sm font-semibold leading-6 text-gray-900",
      },
      help: "dark:text-gray-400",
    },
    input: {
      base: "transition-all disabled:bg-gray-200",
    },
  },
});
