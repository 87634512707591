export function useTime() {
  const { $dayjs } = useNuxtApp();
  const now = useState(() => $dayjs());

  let interval: NodeJS.Timeout;
  function startRealtimeUpdateTimer() {
    interval = setInterval(() => {
      now.value = $dayjs();
    }, 1000);
  }
  function stopRealtimeUpdateTimer() {
    clearInterval(interval);
  }

  return { now, startRealtimeUpdateTimer, stopRealtimeUpdateTimer };
}
