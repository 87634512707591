import { useZoomSdk } from "./useZoomSdk";

export async function universalNavigateTo({
  name,
  url,
  target = "_self",
}: {
  name?: string;
  url?: string;
  target?: "_blank" | "_self";
}) {
  const { isZoomApp, openUrl } = useZoomSdk();
  const runtimeConfig = useRuntimeConfig();

  if (isZoomApp.value) {
    // `target` option is ignored for the Zoom client app. only _blank is supported.
    const supabase = useSupabaseClient();
    const { data } = await supabase.auth.getSession();

    let targetUrl: string = "";

    if (name) {
      const route = useRouter()
        .getRoutes()
        .find((i) => i.name === name);
      if (route) {
        targetUrl = runtimeConfig.public.BASE_URL + route.path;
      }
    } else if (url) {
      targetUrl = url;
    }

    if (targetUrl) {
      const _url = new URL(targetUrl);

      if (data.session?.access_token && data.session.refresh_token) {
        _url.searchParams.append("access_token", data.session.access_token);
        _url.searchParams.append("refresh_token", data.session.refresh_token);
      }

      openUrl(_url.href);
    } else {
      console.error("invalid route", name, url);
    }
  } else {
    if (url) {
      if (target === "_blank") {
        window.open(url);
      } else {
        location.href = url;
      }
    } else if (name) {
      await navigateTo({ name }, { open: { target } });
    }
  }
}
