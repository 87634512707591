import _dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime.js";
import localizedFormat from "dayjs/plugin/localizedFormat";

_dayjs.extend(utc);
_dayjs.extend(duration);
_dayjs.extend(relativeTime);
_dayjs.extend(localizedFormat);

export { _dayjs as dayjs };
