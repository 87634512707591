export default defineNuxtRouteMiddleware(async (to) => {
  try {
    await useAuth().detectSessionInUrl(to);
    if (to.query.access_token || to.query.refresh_token) {
      delete to.query.access_token;
      delete to.query.refresh_token;
      return navigateTo({ path: to.path, query: to.query });
    }

    const { user } = useUser();
    if (user.value) {
      return;
    }

    await useAuth().fetchMe();
  } catch (err) {
    console.error("failed fetch user data.", err);
  }
});
