const Constants = {
  Title:
    "EventCAT - Real-Time Multilingual Solution for Online Meeting and Conference",
  Description:
    "Invite EventCAT bot to your online call. It translates the speech of all participants in real time. We currently support over 40+ languages.",
  ImageUrl:
    "https://cmdhxpwmlalsaqwxippw.supabase.co/storage/v1/object/public/images/opengraph/main@2x.png",
};

export function useSeo(options?: {
  title?: string;
  description?: string;
  ogImage?: string;
}) {
  const title = options?.title || Constants.Title;
  const description = options?.description || Constants.Description;
  const ogImage = options?.ogImage || Constants.ImageUrl;
  const url = useRuntimeConfig().public.BASE_URL + useRoute().path;

  useSeoMeta({
    title: title,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageUrl: ogImage,
    ogUrl: url,
    ogImageType: "image/png",
    charset: "utf-8",
    robots: "index follow",
    ogSiteName: "EventCAT",
    colorScheme: "only light",
  });

  useHead({
    link: [
      {
        rel: "canonical",
        href: url,
      },
    ],
  });
}
