import { datadogRum } from "@datadog/browser-rum";

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();

  if (runtimeConfig.public.IS_PROD) {
    datadogRum.init({
      applicationId: runtimeConfig.public.DATADOG_APPLICATION_ID as string,
      clientToken: runtimeConfig.public.DATADOG_CLIENT_TOKEN as string,
      site: "datadoghq.com",
      service: "www.eventcat.com",
      env: runtimeConfig.public.ENV,
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      silentMultipleInit: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  }

  return { provide: { datadogRum } };
});
